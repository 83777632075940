exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidad-mdx": () => import("./../../../src/pages/legal/politica-de-privacidad.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-mdx" */),
  "component---src-pages-legal-terminos-de-uso-mdx": () => import("./../../../src/pages/legal/terminos-de-uso.mdx" /* webpackChunkName: "component---src-pages-legal-terminos-de-uso-mdx" */),
  "component---src-pages-rrhh-js": () => import("./../../../src/pages/rrhh.js" /* webpackChunkName: "component---src-pages-rrhh-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-segment-js": () => import("./../../../src/templates/segment.js" /* webpackChunkName: "component---src-templates-segment-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

